import React, { useCallback, useEffect, useId, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, noop } from 'lodash';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Input from 'reactstrap/lib/Input';
import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { ENTER_KEY_VALUE } from 'site-modules/shared/constants/key-values';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
// eslint-disable-next-line venom/no-venom-api-calls
import { VenomApi } from 'client/data/api/api-client';

import './zip-code-form.scss';

export function ZipCodeFormUI({
  setModelValue,
  onSuccess,
  className,
  btnClassName,
  titleClassName,
  subTitleClassName,
  zipInputClassName,
  title,
  subTitle,
  btnTitle,
  colConfig,
  trackingParent,
  errorMessage,
  isErrorBelow,
  fireWidgetView,
}) {
  const inputId = `zip-${useId()}`;
  const [isPending, setPending] = useState(false);
  const [isInvalid, setInvalid] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (fireWidgetView) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
        event_data: {
          action_name: TrackingConstant.ACTION_WIDGET_VIEW,
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          creative_id: trackingParent,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFocus = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
        subaction_name: 'click_in_field',
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        creative_id: trackingParent,
      },
    });
  }, [trackingParent]);

  const onChange = useCallback(() => {
    setInvalid(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    const zipValue = get(inputRef, 'current.value', '');

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
        subaction_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: trackingParent,
        value: zipValue,
      },
    });

    if (!validation.validateZip(zipValue)) {
      setInvalid(true);
      return;
    }

    setPending(true);

    // eslint-disable-next-line venom/no-venom-api-calls
    const locationResponse = await VenomApi.fetchJson(`/location/zip/${zipValue}`, {
      credentials: 'same-origin',
    }).catch(noop);

    const isValid = get(locationResponse, 'success') && get(locationResponse, 'location.zipCode');
    if (isValid) {
      setModelValue('location', VisitorModel, { locationResponse }).then(onSuccess);

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
          subaction_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          creative_id: trackingParent,
          value: zipValue,
        },
      });
    }

    setInvalid(!isValid);
    setPending(false);
  }, [onSuccess, setModelValue, trackingParent]);

  const onKeyPress = useCallback(
    e => {
      if (e.key === ENTER_KEY_VALUE) {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const errorCol = (
    <Col
      xs={12}
      {...colConfig.error}
      className={classnames('text-danger label mt-0_25', { invisible: !isInvalid })}
      aria-hidden={!isInvalid}
    >
      {errorMessage}
    </Col>
  );

  return (
    <Row className={classnames('zip-code-form text-gray-darker', className)} data-tracking-parent={trackingParent}>
      <Col xs={12} {...colConfig.title}>
        {!!title && <div className={titleClassName}>{title}</div>}
        {!!subTitle && <div className={subTitleClassName}>{subTitle}</div>}
      </Col>

      <Col xs={12} {...colConfig.zip} className="zip-input-wrapper">
        <Input
          id={inputId}
          innerRef={inputRef}
          name="zip"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          className={classnames('zip-input pl-1_25', zipInputClassName, { invalid: isInvalid })}
          onFocus={onFocus}
          onChange={onChange}
          onKeyPress={onKeyPress}
          data-test="zip-input"
          placeholder="Zip code"
          aria-label="Zip Code"
        />
      </Col>

      {!isErrorBelow && errorCol}

      <Col xs={12} {...colConfig.btn}>
        <Button
          onClick={handleSubmit}
          className={classnames('submit-zip-btn px-0_75 py-0_5 w-100 text-transform-none', btnClassName)}
          size="lg"
          color="blue-50"
          disabled={isPending}
        >
          {isPending ? <Spinner color="gray" className="align-baseline" size={16} /> : btnTitle}
        </Button>
      </Col>

      {isErrorBelow && errorCol}
    </Row>
  );
}

ZipCodeFormUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subTitleClassName: PropTypes.string,
  zipInputClassName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  btnTitle: PropTypes.string,
  trackingParent: PropTypes.string,
  errorMessage: PropTypes.string,
  colConfig: PropTypes.shape({
    title: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    zip: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    error: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    btn: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  }),
  isErrorBelow: PropTypes.bool,
  fireWidgetView: PropTypes.bool,
};

ZipCodeFormUI.defaultProps = {
  onSuccess: noop,
  className: null,
  btnClassName: null,
  titleClassName: 'heading-5 mb-0_5',
  subTitleClassName: 'mb-0_75',
  zipInputClassName: null,
  trackingParent: 'international-zip-cta',
  title: 'Oops, we can’t seem to locate you.',
  subTitle: 'Enter a U.S. ZIP code to see pricing.',
  btnTitle: 'See Pricing',
  errorMessage: 'Please enter a valid ZIP code.',
  colConfig: {},
  isErrorBelow: false,
  fireWidgetView: false,
};

export const ZipCodeForm = connectToModel(ZipCodeFormUI, {});
