import PropTypes from 'prop-types';
import { merge, noop } from 'lodash';

/* Constants */
import { VehicleEntities } from 'client/data/models/vehicle';
import { InventoryEntities } from 'client/data/models/inventory';
import {
  CARD_VIEW,
  LEAD_TYPES,
  LEAD_FORM_VIEWS,
  THANK_YOU_VIEWS,
  USER_INFO_POSITION,
  HEADER_COMPONENT,
  THANK_YOU_AD_COMPONENT,
  COLLAPSE_STATE,
} from 'site-modules/shared/constants/lead-form/lead-form-params';
import { LEADDELIVERY_API_URL } from 'site-modules/shared/constants/lead-form/lead-form-configs';
import { SRP_AD } from 'site-modules/shared/components/native-ad/utils/constants';

/* Utils */
import { EdmundsAPI } from 'client/data/api/api-client';
import { getLeadSubmissionInfo } from 'site-modules/shared/components/lead-form/utils/utils';
import { getMetricsHeaders } from 'client/utils/metrics-header';
import { SCROLL_MAP } from 'site-modules/shared/constants/lead-form/validation';

/* Lead Form Wrapper methods */
import { processResult } from 'client/site-modules/shared/components/lead-form/lead-form-wrapper/lead-form-wrapper-methods';

export const LeadFormParamsPropTypes = {
  leadTargets: PropTypes.arrayOf(
    PropTypes.shape({
      inventory: PropTypes.shape({}),
      dealer: PropTypes.shape({}),
      leadAttributes: PropTypes.shape({
        primary: PropTypes.bool.isRequired,
        distance: PropTypes.number,
      }).isRequired,
    })
  ).isRequired,
  make: PropTypes.string,
  model: PropTypes.string,
  submodel: PropTypes.string,
  trim: PropTypes.string,
  specialIdentifier: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stateCode: PropTypes.string,
};

export const LeadFormParamsDefaultProps = {
  make: '',
  model: '',
  submodel: '',
  trim: '',
  specialIdentifier: '',
  year: '',
  styleId: '',
  stateCode: '',
};

export const LeadFormCommonPropTypes = {
  ...LeadFormParamsPropTypes,
  leadFormData: PropTypes.shape({}),
  showListTargetsLoadingIndicator: PropTypes.bool,
  showSubmitLoadingIndicator: PropTypes.bool,
  selectedLeadIds: PropTypes.shape({}),
  onToggleLeadSelection: PropTypes.func.isRequired,
  fieldRef: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({}),
  onUpdateStyle: PropTypes.func,
  styles: VehicleEntities.Styles,
  resetFieldValidation: PropTypes.func,
};

export const LeadFormCommonDefaultProps = {
  ...LeadFormParamsDefaultProps,
  leadFormData: {},
  showListTargetsLoadingIndicator: false,
  showSubmitLoadingIndicator: false,
  selectedLeadIds: {},
  validationErrors: {},
  onUpdateStyle: null,
  styles: [],
  resetFieldValidation: null,
};

export const LeadFormPagePropTypes = {
  vin: PropTypes.string,
  zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  make: PropTypes.string,
  model: PropTypes.string,
  submodel: PropTypes.string,
  // it is required for leadType = inventory and strategy = purchase
  submodelIdentifier: PropTypes.string,
  styleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  trim: PropTypes.string,
  specialIdentifier: PropTypes.string,
  pathname: PropTypes.string,
  leadTargets: PropTypes.arrayOf(PropTypes.shape({})),
};

export const LeadFormPageDefaultProps = {
  radius: undefined,
  make: undefined,
  model: undefined,
  submodel: undefined,
  submodelIdentifier: undefined,
  styleId: undefined,
  trim: undefined,
  specialIdentifier: undefined,
  pathname: undefined,
  leadTargets: [],
};

// CUSTOM CONFIG PROP TYPES
export const HeaderConfigPropTypes = {
  headerComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  header: PropTypes.shape({
    showLogo: PropTypes.bool,
    srpLink: PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    }),
    text: PropTypes.string, // general header text
    explainingText: PropTypes.string,
    className: PropTypes.string,
    showSeparator: PropTypes.string,
    tag: PropTypes.string,
  }),
  subHeaderComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  subHeader: PropTypes.shape({
    skipWhenLeadTargetEmpty: PropTypes.bool,
    text: PropTypes.string,
    explainingText: PropTypes.string,
    className: PropTypes.string,
    insiderLinkOut: PropTypes.bool,
    insiderBtnClasses: PropTypes.string,
  }),
};

export const HeaderConfigDefaultProps = {
  headerComponent: HEADER_COMPONENT.BASE,
  header: {
    showLogo: true,
    srpLink: undefined,
    text: '',
    explainingText: '',
    showSeparator: 'true', // can be a calculated, e.g. [savingsValue=0?true:false]
    tag: 'div',
  },
  subHeaderComponent: undefined,
  subHeader: {
    skipWhenSuggestedLeadTargetsEmpty: false,
    text: '',
    explainingText: '',
    insiderBtnClasses: 'btn-primary-b',
  },
};

export const CommonConfigPropTypes = {
  showInventoryMakeStockImage: PropTypes.bool,
  showDealerMakeStockImage: PropTypes.bool,
  priceStrikethrough: PropTypes.bool,
  options: PropTypes.shape({}),
};

export const AdConfigPropTypes = {
  adsPositionsConfig: PropTypes.shape({}),
  adComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
};

export const AdConfigDefaultProps = {
  adComponent: THANK_YOU_AD_COMPONENT.BASE,
};

export const CollapseStatePropTypes = PropTypes.oneOf([COLLAPSE_STATE.PARTIAL_COLLAPSE]);

export const ExplainingTextParamsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })
);

export const LeadFormCheckboxPropTypes = PropTypes.shape({
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  isVisible: PropTypes.func,
  fireIsVisibleTracking: PropTypes.func,
});

export const LeadFormCheckboxDefaultProps = {
  label: '',
  defaultChecked: false,
  isVisible: noop,
  fireIsVisibleTracking: noop,
};

export const UnifiedLeadFormCustomConfigPropTypes = {
  creativeId: PropTypes.string,
  isUserOpened: PropTypes.bool,
  noLeadTargets: PropTypes.bool,
  leadType: PropTypes.string, // LEAD_TYPES.ANY, LEAD_TYPES.INVENTORY, INVENTORY.DEALER
  featureFlags: PropTypes.string, // Comma delimeted string which gets passed to lead submission API payload.
  apiParams: PropTypes.shape({
    leadTargetsSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // if unspecified, default number for strategy will be returned
    leadSubmissionAPI: PropTypes.object, // by default, EdmundsAPI is used for lead submission
    leadSubmissionURL: PropTypes.string, // by default, LEADDELIVERY_API_URL is used
    omitStyleId: PropTypes.bool,
    omitYear: PropTypes.bool,
    excludeParentDealership: PropTypes.string,
  }),
  processDataMethods: PropTypes.shape({
    getLeadSubmissionInfo: PropTypes.func, // override in case if you need to change submission payload
    getLeadSubmissionAPIHeaders: PropTypes.func, // overrides submission API headers for request if specified
    getInsiderSaveData: PropTypes.func, // override in case if you need to change data that will be saved to insider
    processLeadTargetsAfterSubmit: PropTypes.arrayOf(PropTypes.func), // override in case if you need specific logic for thank you leads processing; Note: SHOULD BE A FUNCTION THAT RETURNS A PROMISE
    processCheckedCheckbox: PropTypes.func,
    postProcessMethods: PropTypes.arrayOf(PropTypes.func), // additional logic to be done after submit; Note: SHOULD BE A FUNCTION THAT RETURNS A PROMISE
  }),
  leadFormComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  leadFormConfig: PropTypes.shape({
    ...CommonConfigPropTypes,
    className: PropTypes.string,
    cardFirstText: PropTypes.string,
    cardSecondText: PropTypes.string,
    cardView: PropTypes.string,
    disableInventoryCard: PropTypes.bool,
    applyPhoneMask: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    enableComments: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    submitButtonText: PropTypes.string, // Can be separated by pipe (|) for one|multiple dealers in the list
    userInfoPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // USER_INFO_POSITION.BEFORE_CARDS, USER_INFO_POSITION.AFTER_CARDS or 1,2,3...
    vehicleString: PropTypes.string,
    dealerName: PropTypes.string,
    showBestDealPrice: PropTypes.bool,
    preSelectedCardNumbers: PropTypes.number,
    toggleableSubmitInfo: PropTypes.bool,
    initialCollapseState: CollapseStatePropTypes,
    withFormClasses: PropTypes.bool,
    hideWhenLeadTargetsEmpty: PropTypes.bool,
    headerConfig: PropTypes.shape(HeaderConfigPropTypes),
    hideStyleSelector: PropTypes.bool,
    showVin: PropTypes.bool,
    showPrice: PropTypes.bool,
    reverseCardView: PropTypes.bool,
    listCardView: PropTypes.bool,
    showProTip: PropTypes.bool,
    showPQtext: PropTypes.bool,
    filteringByFacets: InventoryEntities.Facets,
    scrollMap: PropTypes.arrayOf(
      PropTypes.shape({
        input: PropTypes.string,
        selector: PropTypes.string,
      })
    ),
    tcpaParentDealershipName: PropTypes.string,
    daysToSell: PropTypes.number,
    isMobile: PropTypes.bool,
    isTopEmbeddedCommentsAvailable: PropTypes.bool,
    checkbox: LeadFormCheckboxPropTypes,
    leadFormId: PropTypes.string,
    isUsedSrpDealCheck: PropTypes.bool,
    skipWidgetViewTracking: PropTypes.bool,
    fragmentTop: PropTypes.node,
    trackedCreativeId: PropTypes.string,
    isNewVinOnSrp: PropTypes.bool,
    bestDealPrice: PropTypes.number,
    userInfoComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    userCongratsInfoComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    userInfoOptions: PropTypes.shape({}),
    hideContactDealerTip: PropTypes.bool,
    isPriceChecker: PropTypes.bool,
    buyonline: PropTypes.bool,
    showLeasePayments: PropTypes.bool,
    vinData: PropTypes.shape({}),
    isNewVin: PropTypes.bool,
    isJLR: PropTypes.bool,
    useGraphQlForLoanOffers: PropTypes.bool,
    submitButtonColor: PropTypes.string,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    isInStockOnline: PropTypes.bool,
    hideLeadForm: PropTypes.bool,
    titleCentered: PropTypes.bool,
    fullWidthCta: PropTypes.bool,
  }),
  emptyLeadFormComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  thankYouComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  thankYouConfig: PropTypes.shape({
    ...CommonConfigPropTypes,
    showPricingDetails: PropTypes.bool,
    className: PropTypes.string,
    withFormClasses: PropTypes.bool,
    headerConfig: PropTypes.shape(HeaderConfigPropTypes),
    adsPositionsConfig: PropTypes.shape({}),
    adsConfig: PropTypes.shape(AdConfigPropTypes),
    hasPriceCheckerPromo: PropTypes.bool,
    hasEmailTreehousePromotion: PropTypes.bool,
    forceNoNciView: PropTypes.bool,
    priceCheckerParams: PropTypes.shape({
      priceCheckerPromoQuery: PropTypes.objectOf(PropTypes.string),
      bestDealPrice: PropTypes.number,
      isCloseButton: PropTypes.bool,
    }),
    isBuildPriceAdEnabled: PropTypes.bool,
    hideCtas: PropTypes.bool,
  }),
  explainingTextParams: ExplainingTextParamsPropTypes,
  hideDelighted: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export const UnifiedLeadFormCustomConfigDefaultProps = {
  leadType: LEAD_TYPES.INVENTORY,
  isUserOpened: false,
  noLeadTargets: false,
  leadFormComponent: LEAD_FORM_VIEWS.BASE,
  apiParams: {
    leadTargetsSize: '',
    leadSubmissionAPI: EdmundsAPI,
    leadSubmissionURL: LEADDELIVERY_API_URL,
    omitStyleId: false,
    omitYear: false,
    excludeParentDealership: null,
  },
  processDataMethods: {
    getLeadSubmissionInfo,
    getLeadSubmissionAPIHeaders: getMetricsHeaders,
    getInsiderSaveData: noop,
    processLeadTargetsAfterSubmit: [processResult],
    processCheckedCheckbox: noop,
    postProcessMethods: [noop],
  },
  leadFormConfig: {
    className: null,
    cardView: CARD_VIEW.DEFAULT,
    applyPhoneMask: true,
    submitButtonText: 'Submit',
    userInfoPosition: USER_INFO_POSITION.AFTER_CARDS,
    showIncentives: false,
    vehicleString: '',
    dealerName: '',
    withFormClasses: true,
    hideWhenLeadTargetsEmpty: false,
    initialCollapseState: null,
    enableBottomNote: false,
    reverseCardView: false,
    listCardView: false,
    showProTip: false,
    showPQtext: true,
    headerConfig: merge({}, HeaderConfigDefaultProps, {
      header: {
        text: '',
      },
    }),
    hideStyleSelector: false,
    showVin: false,
    showPrice: false,
    filteringByFacets: [],
    scrollMap: SCROLL_MAP,
    tcpaParentDealershipName: null,
    daysToSell: null,
    isMobile: false,
    isTopEmbeddedCommentsAvailable: false,
    checkbox: LeadFormCheckboxDefaultProps,
    leadFormId: '',
    isUsedSrpDealCheck: false,
    skipWidgetViewTracking: false,
    fragmentTop: null,
    trackedCreativeId: null,
    isNewVinOnSrp: false,
    bestDealPrice: 0,
    userInfoComponent: undefined,
    userCongratsInfoComponent: undefined,
    userInfoOptions: {},
    hideContactDealerTip: false,
    isPriceChecker: false,
    buyonline: false,
    showLeasePayments: false,
    vinData: {},
    isNewVin: false,
    useGraphQlForLoanOffers: false,
    isJLR: false,
    submitButtonColor: 'primary-b',
    isLoading: false,
    isInStockOnline: false,
    hideLeadForm: false,
    titleCentered: false,
    fullWidthCta: false,
  },
  emptyLeadFormComponent: null,
  thankYouComponent: THANK_YOU_VIEWS.BASE,
  thankYouConfig: {
    className: null,
    withFormClasses: true,
    headerConfig: merge({}, HeaderConfigDefaultProps, {
      header: {
        text: 'Success!',
      },
    }),
    adsConfig: {
      ...AdConfigDefaultProps,
      adsPositions: { [SRP_AD.AD_NAME]: '7' },
    },
    hasPriceCheckerPromo: false,
    hasEmailTreehousePromotion: false,
    forceNoNciView: false,
    priceCheckerParams: null,
    isBuildPriceAdEnabled: false,
    hideCtas: false,
  },
  explainingTextParams: [],
  hideDelighted: true,
  tags: null,
  renderFormServerSide: false,
  useLeadTargetsFromProps: false,
};

export const QuickQuotesLeadFromProps = {
  onUpdateStyle: PropTypes.func.isRequired,
  styles: VehicleEntities.Styles,
};

export const QuickQuotesLeadFromDefaultProps = {
  styles: [],
};

export const ConfiguratorLeadFromProps = {
  onUpdateStyle: PropTypes.func.isRequired,
  styles: VehicleEntities.Styles,
};

export const ConfiguratorLeadFromDefaultProps = {
  styles: [],
};

export const LeadTargetProp = PropTypes.shape({
  inventory: PropTypes.shape({}),
  dealer: PropTypes.shape({}),
  leadAttributes: PropTypes.shape({
    primary: PropTypes.bool.isRequired,
    distance: PropTypes.number,
  }).isRequired,
});
