import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { get, debounce } from 'lodash';

import {
  LeadFormCommonPropTypes,
  LeadFormCommonDefaultProps,
  UnifiedLeadFormCustomConfigPropTypes,
  UnifiedLeadFormCustomConfigDefaultProps,
} from 'site-modules/shared/components/lead-form/lead-form-prop-types/lead-form-prop-types';
import { IntersectionObserver } from 'client/utils/intersection-observer';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getTemplateParams } from 'site-modules/shared/components/lead-form/utils/utils';

/**
 * Form view decorator.
 */
function FormViewDecoratorUI(WrappedComponent, className = 'px-md-1_25 pb-3_5 mb-3_5') {
  return class ViewDecoratorComponent extends Component {
    static propTypes = {
      ...LeadFormCommonPropTypes,
      customConfig: UnifiedLeadFormCustomConfigPropTypes.leadFormConfig,
      profile: PropTypes.shape({}),
      pageName: PropTypes.string,
      isMobile: PropTypes.bool,
    };

    static defaultProps = {
      ...LeadFormCommonDefaultProps,
      customConfig: UnifiedLeadFormCustomConfigDefaultProps.leadForm,
      profile: {},
      pageName: null,
      isMobile: false,
    };

    constructor(props) {
      super(props);

      this.leadFormTemplateRef = React.createRef();
    }

    componentDidMount() {
      if (get(this.props, 'customConfig.trackedCreativeId')) {
        this.observer = new IntersectionObserver(this.checkIntersection);
        this.setObserver();
      }
    }

    componentWillUnmount() {
      if (this.observer) {
        this.observer.disconnect();
      }
    }

    // Debounce used to not fire before component loads
    setObserver = debounce(() => {
      this.observer.observe(this.leadFormTemplateRef.current);
    }, 1000);

    checkIntersection = changes => {
      changes.forEach(change => {
        if (change.isIntersecting) {
          this.trackLeadFormView();
        }
      });
    };

    trackLeadFormView = () => {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
        event_data: {
          action_name: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
          action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
          creative_id: get(this.props, 'customConfig.trackedCreativeId'),
        },
      });
    };

    render() {
      const { customConfig } = this.props;
      const {
        fragmentTop,
        headerConfig,
        withFormClasses,
        className: leadFormClassName,
        ...otherCustomConfig
      } = customConfig;
      const wrappedProps = {
        ...this.props,
        customConfig: {
          headerConfig,
          ...otherCustomConfig,
        },
      };
      const {
        headerComponent: HeaderComponent,
        header,
        subHeaderComponent: SubHeaderComponent,
        subHeader,
      } = headerConfig;

      const templateParams = getTemplateParams(this.props);

      return (
        <Fragment>
          {fragmentTop}
          <div
            ref={this.leadFormTemplateRef}
            className={classNames('base-template mx-auto', leadFormClassName, { [className]: withFormClasses })}
          >
            {HeaderComponent && <HeaderComponent config={header} templateParams={templateParams} />}
            {SubHeaderComponent && <SubHeaderComponent config={subHeader} templateParams={templateParams} />}
            <WrappedComponent {...wrappedProps} templateParams={templateParams} />
          </div>
        </Fragment>
      );
    }
  };
}

export function FormViewDecorator(WrappedComponent, className) {
  const mapStateToProps = state => ({
    isMobile: get(state, 'mobile'),
    profile: get(state, 'profile.data.idm'),
    pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
  });
  return connect(mapStateToProps)(FormViewDecoratorUI(WrappedComponent, className));
}
